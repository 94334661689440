// requests:
export const LIST_APPOINTMENTS_IN_RANGE_REQ = 'list-appointments-in-range-request'
export const ACTIVE_APPOINTMENT_DETAIL = 'list-appointment-detail-request'
export const LIST_CLIENTS_REQ = 'clients-list-request'
export const SEARCH_CLIENTS_REQ = 'clients-search-request'
export const LIST_ACTIVE_CLIENTS_REQ = 'clients-list-active-request'
export const LIST_INVOICES_IN_RANGE_REQ = 'list-invoices-in-range-request'
export const LIST_TRANSACTIONS_IN_RANGE_REQ = 'list-transactions-in-range-request'
export const DELETE_INVOICE_REQ = 'delete-invoice-request'
export const LIST_GROUPS_REQ = 'groups-list-request'
export const GET_ACTIVE_INVOICE = 'get-active-invoice-request'
export const CREATE_APPOINTMENT_REQ = 'create-appointment-request'
export const SAVE_INVOICE_REQ = 'save-invoice-request'
export const GET_INVOICE_SETTINGS = 'invoicesettings-req'
export const SAVE_PAYMENT_REQ = 'save-payment-req'
export const CODE_SUGGESTIONS = 'suggestionsReqId'
export const CODE_CUD_REQ = 'appointment-code-req'
export const NOTE_SAVE_REQ = 'appointment-notes-request'
export const GET_CLIENT_RELATIONSHIP_REQUEST = 'get-relationship-request'

export const GET_ME_REQ = 'me-request'
export const GET_LOOKUPS_REQ = 'get-me-lookups-request'
export const CLIENT_REQ = (id) => { return 'client-detail-request-' + id }
export const APPOINTMENT_REQ = (id) => { return 'appointment-detail-request-' + id }
export const INVOICE_REQ = (id) => { return 'invoice-detail-request-' + id }
export const MEDICAL_RECORD_REQ = (id) => { return 'medical-aid-get-request-' + id }
export const MEDICAL_AID_SAVE_RECORD = 'medical-aid-save-request'
export const CLIENT_PAGE_SIZE = 300
export const CLIENT_ACTIVE_LIMIT = 2400

export const ACTIVE = {
  'PRACTITIONER': 'practitioner',
  'CLIENT': 'client',
  'APPOINTMENT': 'appointment'
}

// module: application
// mutations:
export const ACTIVATE_RANGE_PICKER = 'ACTIVATE_RANGE_PICKER'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN'
export const TRIGGER_EVENT = 'TRIGGER_EVENT'
export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const SET_SETTING = 'SET_SETTING'
export const SET_GLOBAL_APPOINTMENT = 'SET_GLOBAL_APPOINTMENT'
export const SET_ACTIVE_APPOINTMENT = 'SET_ACTIVE_APPOINTMENT'
export const SET_ACTIVE_DIALOG = 'SET_ACTIVE_DIALOG'
export const SET_ACTIVE_DATE_RANGE = 'SET_ACTIVE_DATE_RANGE'
export const SET_SPACE_VALUE = 'SET_SPACE_VALUE'
export const SET_SPACE = 'SET_SPACE'
export const HIDE_SPACE = 'HIDE_SPACE'
// actions:
export const INIT = 'INIT'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const SET_POST_LOGIN_REDIRECT = 'SET_POST_LOGIN_REDIRECT'
export const SHOW_APPOINTMENT_DIALOG = 'SHOW_APPOINTMENT_DIALOG'

export const STATUSES = {
  'B': { title: 'Blocked', color: '#f0f0f0', bright: '#607D8B', icon: 'ic_remove_circle_outline', theme: 'grey lighten-2' },
  'N': { title: 'New', color: '#00D8FF', bright: '#2196F3', icon: 'ic_event', theme: 'blue' },
  'C': { title: 'Cancelled', color: '#F7464A', bright: '#F44336', icon: 'ic_not_interested', theme: 'red lighten-1' },
  // 'PR': { title: 'Payment Requested', color: '#fcf8e3', icon: 'ic_payment', theme: 'yellow lighten-1' },
  'I': { title: 'Invoiced', color: '#c9c9ff', bright: '#00BCD4', icon: 'receipt', theme: 'light-blue' },
  'P': { title: 'Paid', color: '#e1f7d5', bright: '#4CAF50', icon: 'ic_account_balance', theme: 'success' }
  // 'CMPLT': { title: 'Complete', color: '#46BFBD', icon: 'ic_stars', theme: 'green lighten-1' }
}
export const PAYABLE_STATUSES = ['B', 'N', 'C', 'I']
export const TITLES = ['Dr', 'Mr', 'Mrs', 'Miss', 'Ms', 'Mx', 'Baby boy', 'Baby girl']
export const CODETYPES = ['icd10', 'process', 'procedure', 'nappi']

export const CHARTCOLORS = {
  'red': 'rgb(255, 99, 132)',
  'orange': 'rgb(255, 159, 64)',
  'yellow': 'rgb(255, 205, 86)',
  'green': 'rgb(75, 192, 192)',
  'blue': 'rgb(54, 162, 235)',
  'purple': 'rgb(153, 102, 255)',
  'grey': 'rgb(201, 203, 207)'
}

export const ACTIONS = {
  'ASSIGN_PAYMENT': 'payment_transaction_assignment'
}

export const PAYMENT_METHODS = [
  {
    value: 'eft',
    text: 'EFT'
  },
  {
    value: 'cash',
    text: 'Cash'
  },
  {
    value: 'snapscan',
    text: 'SnapScan'
  },
  {
    value: 'credit_card',
    text: 'Creditcard'
  },
  {
    value: 'medicalaid',
    text: 'Medical Aid'
  },
  {
    value: 'gift',
    text: 'Gift'
  },
  {
    value: 'client_credit',
    text: 'Client Credit'
  },
  {
    value: 'voucher',
    text: 'Voucher'
  },
  {
    value: 'write_off',
    text: 'Write off'
  },
  {
    value: 'other',
    text: 'Other'
  }
]

export const SMALL_BREAKPOINT = 1024
export const DEFAULT_TIMEOUT = 2000
// object lookups:
export const APPOINTMENT_OBJ = 'appointment'

// processes:
export const RESCHEDULE_PROCESS = 'schedule.models.AppointmentProcess.reschedule'
export const CANCEL_PROCESS = 'schedule.models.AppointmentProcess.cancel'
export const SEND_PAYMENT_REQUEST = 'schedule.models.AppointmentProcess.requestPayment'
export const MARK_AS_PAID = 'schedule.models.AppointmentProcess.updateStatus'

/* eslint-disable no-useless-escape */
export const RULES = {
  required: (value) => !!value || 'This field is required.',
  email: (value) => {
    if (!value) { return true }
    const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value) || 'Please provide a valid e-mail address.'
  }
}
