<template>
<v-list-item @click='$emit("selected", item)' >
  <slot name='avatar' >
    <v-list-item-action v-if='withStatus' >
      <v-avatar :color='statusColor' class='white--text' :title='statusTitle' >{{appt.status}}</v-avatar>
    </v-list-item-action>
  </slot>
  <v-list-item-content>
    <v-list-item-title>
      {{appt.title}}
      <!-- <v-chip v-if='appt.service_name' x-small label v-text='appt.service_name' class='float-right' color='primary' outlined /> -->

      <v-chip v-if='appt.is_recurring' x-small label class='ml-2 float-right' >
        Recurring
      </v-chip>
      <v-chip v-if='appt.invoiceable' x-small label class='ml-2 float-right' color='success' >
        Ready for invoicing
      </v-chip>
      <v-chip v-if='appt.is_online' x-small label class='ml-2 float-right' color='primary' >Online</v-chip>
    </v-list-item-title>
    <v-list-item-subtitle>
      {{ appt.product_name }} with {{appt.practitioner_name}} @ {{appt.address_name}}
    </v-list-item-subtitle>
    <v-list-item-subtitle>
      <strong>{{ dateformat(appt.start_time, 'DATE_MED_WITH_WEEKDAY') }}</strong>
      {{ dateformat(appt.start_time, 'TIME_24_SIMPLE') }} -
      {{ dateformat(appt.end_time, 'TIME_24_SIMPLE') }}
    </v-list-item-subtitle>
  </v-list-item-content>
  <v-divider v-if='withPrice' vertical class='ml-2' />
  <slot name='action' >
    <v-list-item-action v-if='withPrice' style='min-width:80px;' >
      {{ formatCurrency(appt.currency, appt.price) }}
      <v-list-item-action-text >
        unit price
      </v-list-item-action-text>
    </v-list-item-action>
  </slot>
</v-list-item>

</template>
<script>
import {STATUSES} from '@/store/constants.js'
export default {
  name: 'AppointmentListItem',
  props: {
    item: { type: Object, required: true },
    withStatus: { type: Boolean, default: true },
    withPrice: { type: Boolean, default: true }
  },
  computed: {
    appt () { return this.item },
    status () {
      if (this.appt.status in STATUSES) { return STATUSES[this.appt.status] }
      return {}
    },
    statusColor () { return this.status.theme },
    statusTitle () { return this.status.title }
  }
}
</script>
