<template>
<div>
  <v-subheader v-if='title' class='overline' v-text=title />

  <template
    v-if='type==="checkbox"' >
    <template v-for="(count, value) in facets" >
      <v-checkbox dense hide-details class='ma-0 pa-0'
        v-if='count > 0'
        :key='value'
        v-model='selected' :value='value'
        :color='getColor(value)'
        :label='getLabel(value, count)' />
    </template>
  </template>
  <v-chip-group v-else v-model='selected' column multiple >
    <template v-for="status in statuses" >
      <v-chip :x-small='xSmall' filter :key="status.id" :value='status.id' >
        {{status.text}}
        <template v-if='getCount(status.id)' >
          <v-avatar
            v-if='xSmall'
            style='height:14px!important;width:14px!important;min-width:14px!important;font-size:8px;'
            right color='teal' >{{ getCount(status.id) }}</v-avatar>
          <v-avatar v-else
            right color='teal' >{{ getCount(status.id) }}</v-avatar>
        </template>
      </v-chip>
    </template>
  </v-chip-group>
</div>
</template>
<script>
import {STATUSES} from '@/store/constants.js'

export default {
  name: 'StatusPicker',
  props: {
    title: { type: String, required: false },
    type: { type: String, required: false },
    value: { type: Array, default: () => { return [] } },
    facets: { type: Object, required: true },
    xSmall: { type: Boolean, default: false }
  },
  methods: {
    getColor (value) {
      if (value in STATUSES) {
        return STATUSES[value].theme
      }
      return null
    },
    getLabel (value, count) {
      let label = value
      if (value in STATUSES) {
        label = STATUSES[value].title
      }
      return `${label} (${count})`
    },
    getCount (id) {
      if (this.facets) {
        if (id in this.facets) {
          return this.facets[id]
        } else {
          return 0
        }
      }
      return false
    }
  },
  mounted () {
    if (this.value && this.value.length > 0) {
      this.selected = this.value
    }
  },
  watch: {
    selected () {
      this.$emit('input', this.selected)
    }
  },
  data () {
    return {
      selected: [],
      statuses: [
        {
          id: 'B',
          text: 'Blocked'
        },
        {
          id: 'N',
          text: 'New'
        },
        {
          id: 'I',
          text: 'Invoiced'
        },
        {
          id: 'P',
          text: 'Paid'
        },
        {
          id: 'C',
          text: 'Cancelled'
        }
      ]
    }
  }
}
</script>
