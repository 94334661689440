<template>
<v-card class='justify-right' >
    <div class='li text-right pr-2' >
      <span class='overline caption' >Appointment price</span><br/>
      <strong>{{ formatCurrency(currency, financials.appointment_unit_price) }}</strong>
    </div>

    <div class='li text-right pr-2'
      v-if='financials.products_total_price && parseInt(financials.products_total_price) > 0' >
      <span class='overline caption' >Product sales</span><br/>
      <strong>{{ formatCurrency(currency, financials.products_total_price) }}</strong>
    </div>
    <div class='li text-right pr-2'
      v-if='financials.appointment_tax && parseInt(financials.appointment_tax) > 0' >
      <span class='overline' >Tax</span><br/>
      <strong>{{ formatCurrency(currency, financials.appointment_tax) }}</strong>
    </div>
    <!-- <v-divider class='my-2' />
    <div class='text-right pr-2' >
      <span class='overline caption' >Total price</span><br/>
      <strong >{{ formatCurrency(currency, financials.final_price) }}</strong>
    </div> -->
    <v-divider class='my-2' />
    <div class='li text-right pr-2'
      v-if='financials.appointment_dicount && parseInt(financials.appointment_dicount) > 0' >
      <span class='overline caption' >Discount</span><br/>
      <strong>{{ formatCurrency(currency, financials.appointment_dicount) }}</strong>
    </div>
    <div class='li text-right pr-2' >
      <span class='overline caption' >Payments</span><br/>
      <strong>{{ formatCurrency(currency, financials.payments_received) }}</strong>
    </div>

    <v-divider />
    <div class='text-right pr-2' >
      <span class='overline caption' >Due</span><br/>
      <strong class='headline' >{{ formatCurrency(currency, financials.final_due) }}</strong>
    </div>

</v-card>
</template>
<script>
export default {
  name: 'AppointmentPriceDetails',
  props: {
    financials: { type: Object, required: true },
    currency: { type: String, required: true }
  }
}
</script>
