<template>
<v-card flat :loading='req.loading' height='100%' class='grey lighten-4' >
  <request-status :request='req' @200='setAppointment' :message200='false' />
  <v-card-title v-if='!hideTitle' class='subtitle-1 overline' >
  {{appointment.title}}
  </v-card-title>
  <v-card-text>

    <v-list >
    <appointment-list-item :item='value' :with-status='false' :with-price='false' >
    </appointment-list-item>
    </v-list>
    <v-divider/>

    <template>
      <v-subheader class='overline' >Links</v-subheader>
      <v-list dense one-line>
        <template v-for='link in links' >
          <v-list-item :key='link.title' :href='link.href' target='_blank' >
            <v-list-item-content>
              <v-list-item-title v-text='link.title' />
              <v-list-item-subtitle v-text='link.href' />
            </v-list-item-content>
            <v-list-item-action >
              <v-icon small >mdi-open-in-new</v-icon>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </template>

    <template v-if='appointment.codes && appointment.codes.length > 0' >
      <v-subheader class='overline' >Codes</v-subheader>
      <v-data-table dense hide-default-footer hide-default-header
        :headers="codeHeaders"
        :items="appointment.codes"
        :items-per-page="20"
        class="elevation-1">
        <template v-slot:item.price="{ item }" >{{ formatCurrency(appointment.currency, item.price) }}</template>
        <template v-slot:footer >
          <v-divider/>
          <v-footer class='text-right' >
            <v-spacer/>
            <span class='mr-3' >
              <strong>Total:</strong>
              {{ formatCurrency(appointment.currency, appointment.price) }}
            </span>
          </v-footer>
        </template>
      </v-data-table>
    </template>

    <template v-if='appointment.lineitems && appointment.lineitems.length > 0' >
      <v-subheader class='overline' >Product sales</v-subheader>
      <v-data-table dense hide-default-footer
        :headers="lineitemHeaders"
        :items="appointment.lineitems"
        :items-per-page="20"
        class="elevation-1">
        <template v-slot:item.price="{ item }" >{{ formatCurrency(appointment.currency, item.unit_price) }}</template>
        <template v-slot:footer >
          <v-divider/>
          <v-footer v-if='appointment.financials && appointment.financials.products_total_price' class='text-right' >
            <v-spacer/>
            <span class='mr-3' >
              <strong>Total:</strong>
              {{ formatCurrency(appointment.currency, appointment.financials.products_total_price) }}
            </span>
          </v-footer>
        </template>
      </v-data-table>
    </template>

    <template v-if='appointment.financials' >
      <div class='mt-4' >
        <v-divider/>
        <v-subheader class='overline' >Financials</v-subheader>
        <appointment-price-details :currency='appointment.currency' :financials='appointment.financials' />
      </div>
    </template>
  </v-card-text>
  <v-footer >
    <v-chip label x-small outlined >{{appointment.id}}</v-chip>
  </v-footer>
  <!-- <pre v-text='appointment.codes' />
  <pre v-text='appointment.financials' /> -->
</v-card>
</template>
<script>
import AppointmentListItem from '@/components/listitems/AppointmentListItem.vue'
import AppointmentPriceDetails from '@/components/ui/AppointmentPriceDetails.vue'
export default {
  name: 'AppointmentCard',
  components: {AppointmentListItem, AppointmentPriceDetails},
  props: {
    value: { type: Object, required: false },
    fetch: { type: Boolean, default: false },
    hideTitle: { type: Boolean, default: false }
  },
  data () {
    return {
      req: {
        resource: 'ag.practitioner.appointments'
      },
      appointment: {},
      codeHeaders: [
        {value: 'icd10'},
        {value: 'process'},
        {value: 'price'}
      ],
      lineitemHeaders: [
        {value: 'quantity', text: 'Qty'},
        {value: 'description', text: 'Description'},
        {value: 'unit_price', text: 'Unit price'}
      ]
    }
  },
  computed: {
    appointmentId () { return this.appointment.id },
    links () {
      if (!this.appointmentId) { return [] }
      return [
        { title: 'Open in AppointmentGuru', href: 'https://app.appointmentguru.co/#/appointments/' + this.appointmentId },
        { title: 'Open client appointment page', href: this.appointment.client_detail_url }
      ]
    }
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        if (this.value) {
          this.appointment = this.value
          if (this.appointment.id) {
            if (this.fetch) { this.get() }
          }
        }
      }
    }
  },
  methods: {
    setAppointment (result) {
      this.appointment = result.result
    },
    get () {
      if (this.appointmentId) {
        this.req.resourceId = this.appointmentId
        this.$request('get', this.req)
      }
    }
   }
}
</script>
