<template>
<v-card :dark='dark' tile >
  <v-card-title class='subtitle-1 overline' >
    Filters
    <v-spacer/>
    <v-btn small outlined rounded
      class='mr-2' :loading='loading'
      @click='appplyFilters' >
      Apply
    </v-btn>
    <v-btn @click='clear' small outlined rounded >
      clear <v-icon small >mdi-filter-off</v-icon>
    </v-btn>
  </v-card-title>
  <v-divider/>
  <v-tabs v-model='activeTab' :dark='dark' dense >
    <v-tab >Month</v-tab>
    <v-tab >Date range</v-tab>
    <v-spacer />
    <v-btn
      v-if='selectedRange.length > 0 || selectedMonth'
      @click='selectedRange=[];selectedMonth=null' icon small class='mt-2 mr-2' ><v-icon>mdi-close-circle</v-icon></v-btn>
    <v-tabs-items v-model='activeTab' >
      <v-tab-item >
        <v-date-picker :dark='dark' no-title full-width class='rounded-0'
          v-model='selectedMonth'
          type='month' ></v-date-picker>
      </v-tab-item>
      <v-tab-item >
        <v-date-picker range no-title full-width class='rounded-0'
          v-model='selectedRange'
          :dark='dark' ></v-date-picker>
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>

  <v-card-text>

    <template v-if='facets && facets.space' >
      <facet-field
        title='Space'
        type='checkbox'
        v-model='selectedSpaces'
        :x-small='false'
        :facets='facets.space'
        :lookup-function='getSpaceById'
        display-field='title' />
    </template>

    <v-divider/>

    <template v-if='facets && facets.practitioner' >
      <facet-field
        title='Practitioner'
        type='checkbox'
        v-model='selectedPractitioners'
        :x-small='false'
        :facets='facets.practitioner'
        :lookup-function='getPractitionerById'
        display-field='full_name' />
    </template>

    <v-divider/>

    <template v-if='facets && facets.status' >
      <status-picker
        title='Status'
        type='checkbox'
        v-model='selectedStatuses'
        :facets='facets.status' >
      </status-picker>
      <!-- <facet-field
        v-model='selectedStatuses'
        :x-small='false' :facets='facets.status' :items='statuses' /> -->
    </template>

    <template >
      <v-subheader class='overline' >Properties</v-subheader>
      <template v-for='prop in properties' >
      <v-checkbox dense hide-details
        class='ma-0 pa-0'
        v-model='prop.model'
        :key='prop.id + prop.value'
        :label='prop.text'
        :disabled='prop.disabled && prop.disabled()' />
      </template>
    </template>

    <v-layout class='my-3' >
      <v-spacer/>
      <v-btn v-if='showadvanced===false' @click='showadvanced=true' text >Show more filters</v-btn>
      <v-btn v-else @click='showadvanced=false' text >Show fewer filters</v-btn>
      <v-spacer/>
    </v-layout>

    <template v-if='showadvanced' >
      <v-divider />
      <relationship-picker v-if='selectedRange && selectedRange.length > 1'
        v-model='selectedRelationships'
        :daterange='selectedRange'
        :filters='filters'
        :facets='facets.relationships'
        :lookup-function='getClientName'
        display-field='full_name' />

      <template v-if='facets && facets.product' >
        <facet-field
          title='Appointment Types'
          v-model='selectedProducts'
          :x-small='true'
          :facets='facets.product'
          :lookup-function='getProductById'
          display-field='title' />
      </template>
    </template>

    <!-- <template v-if='facets && facets.relationships' >
      <v-subheader class='overline' >Clients </v-subheader>
      <facet-field
        v-model='selectedRelationships'
        :lookup-function='getClientName'
        display-field='full_name'
        :x-small='true' :facets='facets.relationships' />
    </template> -->

    <!-- {{selectedClients}} -->
    <!-- <template v-if='facets && facets.address__name' >
      <v-subheader class='overline' >Address </v-subheader>
      <facet-field
        v-model='selectedAddresses'
        :x-small='false' :facets='facets.address__name' />
    </template>

    <template v-if='facets && facets.product__title' >
      <v-subheader class='overline' >Appointment Type </v-subheader>
      <facet-field :x-small='false' :facets='facets.product__title' />
    </template> -->

    <!-- <template >
      <v-subheader class='overline' >Has ICD-10 codes</v-subheader>
      <v-radio-group v-model="hasCodes" row dense >
        <v-radio label="Yes" value="True" ></v-radio>
        <v-radio label="No" value="False"></v-radio>
        <v-chip small @click='hasCodes=null' v-if='hasCodes' >
          <v-icon small left >mdi-close-circle-outline</v-icon>
          clear</v-chip>
      </v-radio-group>
    </template> -->
  </v-card-text>
  <!-- <pre v-text='filters' /> -->
  <v-divider />
  <v-card-actions >
    <v-btn :loading='loading' @click='appplyFilters' block >
      Apply
    </v-btn>
  </v-card-actions>
</v-card>
</template>
<script>

const DT_TABS = {
  MONTH: 0,
  RANGE: 1
}
import FacetField from '@/components/filters/FacetField.vue'
import StatusPicker from '@/components/pickers/StatusPicker.vue'
import RelationshipPicker from '@/components/pickers/RelationshipPicker.vue'
import {mapGetters} from 'vuex'
// import {DateTime} from 'luxon'

export default {
  name: 'AppointmentFilters',
  components: {
    FacetField,
    StatusPicker,
    RelationshipPicker
  },
  props: {
    facets : { type: Object, required: false, default: () => { return {} } },
    params : { type: Object, required: false, default: () => { return {} } },
    title: { type: String, default: 'Filters' },
    appointments : { type: Array, required: false, default: () => { return [] } },
    dark: { type: Boolean, default: true },
    loading: { type: Boolean, default: false }
  },
  data () {
    return {
      activeTab: DT_TABS.MONTH,
      showadvanced: false,
      selectedMonth: null,
      selectedRange: [],
      selectedSpaces: [],
      selectedPractitioners: [],
      selectedProducts: [],
      selectedStatuses: [],
      selectedRelationships: [],
      properties: [
        { text: 'Has ICD-10 codes', id: 'with_codes', value: 'True', model: false, disabled: () => { return this.properties[1].model === true } },
        { text: 'Does not have ICD-10 codes', id: 'without_codes', value: 'True', model: false, disabled: () => { return this.properties[0].model === true } },
        { text: 'Has a discount', id: 'discount_gt', value: 0, model: false },
        { text: 'Has product sales', id: 'with_products', value: 'True', model: false },
        { text: 'Is online', id: 'is_online', value: 'True', model: false },
        { text: 'Is recurring', id: 'is_recurring', value: 'True', model: false },
        { text: 'Marked ready for invoicing', id: 'invoiceable', value: 'True', model: false, disabled: () => { return this.properties[7].model === true } },
        { text: 'Not marked ready for invoicing', id: 'invoiceable', value: 'False', model: false, disabled: () => { return this.properties[6].model === true } }
      ]
    }
  },
  watch: {
    selectedMonth () {
      if (this.selectedMonth) {
        this.selectedRange = this.monthrange(this.selectedMonth)
      }
    }
  },
  mounted () {
    this.selectedRange = this.monthrange()
    this.selectedMonth = this.selectedRange[0].substring(0, 7)
    this.appplyFilters()
  },
  methods: {
    getClientName (id) {
      if (this.appointments && this.appointments.length > 0) {
        let item = this.appointments.find((appt) => {
          if (appt && appt.relationships && appt.relationships.length > 0) {
            let relId = appt.relationships[0]
            return (parseInt(relId) === parseInt(id))
          }
        })
        if (item) { return item }
      }
      return {full_name: id}
    },
    appplyFilters () {
      this.$emit('update:params', this.filters)
    },
    clear () {
      this.selectedMonth = null
      this.selectedRange = []
      this.selectedSpaces = []
      this.selectedPractitioners = []
      this.selectedProducts = []
      this.selectedStatuses = []
      this.selectedRelationships = []
      this.properties.forEach((prop) => { prop.model = false })
      this.$emit('update:params', this.filters)
    }
  },
  computed: {
    ...mapGetters('identity', ['getPractitionerById', 'getSpaceById', 'getProductById']),
    filters () {
      let data = {
        facets: this.params.facets
      }
      if (this.activeTab === DT_TABS.RANGE) {
        if (this.selectedRange && this.selectedRange.length > 0) {
          if (this.selectedRange.length > 0) {
            data.after_utc = this.selectedRange[0]
          }
          if (this.selectedRange.length === 2) {
            data.before_utc = this.selectedRange[1]
          }
        }
      }
      if (this.activeTab === DT_TABS.MONTH) {
        if (this.selectedRange && this.selectedRange.length === 2) {
          data.after_utc = this.selectedRange[0]
          data.before_utc = this.selectedRange[1]
        }
      }
      if (this.selectedPractitioners && this.selectedPractitioners.length > 0) {
        data.practitioners = this.selectedPractitioners
      }
      if (this.selectedStatuses && this.selectedStatuses.length > 0) {
        data.statuses = this.selectedStatuses
      }
      if (this.selectedRelationships && this.selectedRelationships.length > 0) {
        data['relationships-in'] = this.selectedRelationships
      }
      this.properties.forEach((prop) => {
        if (prop.model === true) {
          data[prop.id] = prop.value
        }
      })
      return data
    }
  }
}
</script>
