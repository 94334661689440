<template>
<query-page
  title='Appointments'
  icon='mdi-calendar'
  :request='req'
  :page.sync='page'
  :pages='pages'
  @refresh='get' >

  <downloader
    :request='req'
    :pages='pages'
    :disabled='(count===0)'
    initial-filename='appointments'
    :parser-function="appointmentParser"
    slot='toolbar-actions' />

  <appointment-filter
    v-if='filtervisible'
    @refresh='get'
    :params.sync='params'
    :facets='facets'
    :loading='req.loading'
    slot='filters' />

  <template slot='data' >

    <v-card tile flat >
      <v-system-bar class='caption' >
        <v-spacer />
        Showing {{appointments.length}} of {{count}} appointments
      </v-system-bar>
      <v-overlay absolute :value='req.loading' opacity="0.1" >
        <v-progress-circular size='70' indeterminate color='amber' />
        <p>Loading</p>
      </v-overlay>
      <v-list dense class='pa-0 elevation-0' >
        <template v-for='appt in appointments' >
          <!-- <div :key='appt.id'>{{appt.id}}</div> -->
          <appointment-list-item
            @selected='setSelected'
            :selectable='false'
            :item="appt"
            :key="appt.id"
            :with-action='false' />
          <v-divider :key='`${appt.id}-div`' />
        </template>
      </v-list>
    </v-card>
    <!-- <pre v-text='relationships' /> -->
  </template>

  <v-navigation-drawer fixed right stateless
    v-model='peek' slot='peek'  width='400' >
    <v-toolbar v-if='selected' dense flat class='grey lighten-2' >
      {{selected.title}}
      <v-spacer/>
      <v-chip v-if='selectedStatusTitle' x-small label dark class='mr-2' :color='selectedStatusColor' >
        {{selectedStatusTitle}}
      </v-chip>
      <v-btn @click='peek=false' icon small ><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-divider/>
    <appointment-card v-if='selected' v-model='selected' fetch hide-title />
    <template slot='append' >
      <v-divider/>
      <v-btn tile @click='peek=false' block >close</v-btn>
    </template>
  </v-navigation-drawer>
</query-page>
</template>
<script>
import {STATUSES} from '@/store/constants.js'
import QueryPage from '@/components/layouts/QueryPage.vue'
import AppointmentFilter from '@/components/filters/AppointmentFilter.vue'
import AppointmentListItem from '@/components/listitems/AppointmentListItem.vue'
import AppointmentCard from '@/components/cards/AppointmentCard.vue'
import Downloader from '@/components/ui/Downloader.vue'

export default {
  name: 'AppointmentsPage',
  components: {
    QueryPage,
    AppointmentFilter,
    AppointmentListItem,
    AppointmentCard,
    Downloader
  },
  data () {
    return {
      page: 1,
      pagesize: 20,
      selected: null,
      peek: false,
      filtervisible: true,
      req: {
        resource: 'ag.practitioner.appointments'
      },
      params: {
        facets: 'space,practitioner,product,status,relationships'
      }
    }
  },
  watch: {
    params () { this.get() },
    page () {
      this.params.page = this.page
      this.get()
    }
  },
  computed: {
    appointments () {
      return this.getObjectPath(this.req, 'result.results', [])
    },
    facets () {
      return this.getObjectPath(this.req, 'result.facets', {})
    },
    pages () {
      return this.getObjectPath(this.req, 'result.pages', 1)
    },
    count () {
      return this.getObjectPath(this.req, 'result.count', 1)
    },
    selectedStatus () {
      if (this.selected && this.selected.status in STATUSES) { return STATUSES[this.selected.status] }
      return {}
    },
    selectedStatusColor () { return this.selectedStatus.theme },
    selectedStatusTitle () { return this.selectedStatus.title }
  },
  methods: {
    get () {
      this.peek = false
      this.req.params = this.params
      this.$request('get', this.req)
    },
    setSelected (item) {
      this.selected = item
      this.peek=true
    },
    appointmentParser (data) {
      return data.map((row) => {
        let icdcodes = row.codes.map((code) => { return code.icd10 })
        try {
          row.codes = [...new Set(icdcodes)] // make unique
        } catch (ex) { // IE
          row.codes = icdcodes
        }
        return row
      })
    }
  }
}
</script>
