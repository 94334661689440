<template>
<div >
  <v-subheader class='overline' >
    Clients
    <v-chip x-small @click='selected=[]' class='ml-5' >
      <v-icon x-small left >mdi-close-circle-outline</v-icon>
      clear
    </v-chip>
  </v-subheader>
  <v-chip-group v-model='selected' column multiple disabled >
    <!-- {{appointments}} -->
    <template v-for="rel in relationships" >
      <v-chip :x-small='xSmall' filter
        :key="rel.id" :value='rel.id' >
        {{ rel.first_name }} {{ rel.last_name }}
        <v-avatar
          v-if='xSmall'
          style='height:14px!important;width:14px!important;min-width:14px!important;font-size:8px;'
          right color='teal' >{{ getCount(rel) }}</v-avatar>
        <v-avatar v-else
          right color='teal' >{{ getCount(rel) }}</v-avatar>
      </v-chip>
    </template>
  </v-chip-group>
</div>
</template>
<script>
export default {
  name: 'RelationshipPicker',
  data () {
    return {
      selected: [],
      req: {
        resource: 'ag.practitioner.appointments',
        params: {},
        extra: 'relationship_lookup'
      }
    }
  },
  props: {
    facets: { type: Object, required: true },
    filters: { type: Object, required: true },
    xSmall: { type: Boolean, default: true }
  },
  computed: {
    relationships () {
      return this.getObjectPath(this.req, 'result.results', [])
    }
  },
  mounted () {
    this.get()
  },
  watch: {
    filters () {
      this.get()
    },
    selected () {
      this.$emit('input', this.selected)
    }
  },
  methods: {
    get () {
      if (this.filters) {
        let filterClone = JSON.parse(JSON.stringify(this.filters))
        if ('relationships-in' in filterClone) {
          delete filterClone['relationships-in']
        }
        this.req.params = filterClone
        this.$request('get', this.req)
      }
    },
    getCount (rel) {
      if (rel.id in this.facets) {
        return this.facets[rel.id]
      }
      return 0
    },
    getFullName (relationshipId) {
      if (this.appointments && this.appointments.length > 0) {
        let item = this.appointments.find((appt) => {
          if (appt && appt.relationships && appt.relationships.length > 0) {
            let relId = appt.relationships[0]
            return (parseInt(relId) === parseInt(relationshipId))
          }
        })
        if (item) { return item }
      }
      return {full_name: relationshipId}
    }
  }
}
</script>
