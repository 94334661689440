import { render, staticRenderFns } from "./RelationshipPicker.vue?vue&type=template&id=932ba240&"
import script from "./RelationshipPicker.vue?vue&type=script&lang=js&"
export * from "./RelationshipPicker.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VAvatar,VChip,VChipGroup,VIcon,VSubheader})
